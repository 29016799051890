<template>
  <div class="app-container">
    <div class="top">
      <div class="top_titile">请选择课程时间</div>
      <div class="top_select">
        <el-select v-model="value" placeholder="请选择学年">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="semester"
          placeholder="请选择学期"
          clearable
          style="width: 150px; margin-left: 10px"
          class="filter-item"
        >
          <el-option label="1学期" :value="1" />
          <el-option label="2学期" :value="2" />
        </el-select>
      </div>
    </div>
    <div class="top">
      <div class="top_titile">请选择课程时间</div>
      <div class="top_select">
        <el-select v-model="value" placeholder="请选择院系">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="semester"
          placeholder="请选择年级"
          clearable
          style="width: 150px; margin-left: 10px"
          class="filter-item"
        >
          <el-option label="大一" :value="1" />
          <el-option label="大二" :value="2" />
          <el-option label="大三" :value="3" />
        </el-select>
        <el-select
          v-model="semester"
          placeholder="请选择班级"
          clearable
          style="width: 150px; margin-left: 10px"
          class="filter-item"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="tips">星期一课表安排</div>
    <!-- <div class="box"> -->
      <div class="diaLogBox" v-for="(item, key) in calssList" :key="item.id">
        <el-form ref="substitute" :rules="substituteRules" label-width="80px">
          <el-form-item label="课程名称" prop="core">
            <el-select
              v-model="item.className"
              placeholder="请选择课程"
              clearable
              style="width: 150px; margin-left: 10px"
              class="filter-item"
            >
              <el-option
                v-for="item in professionals"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
            上课教师:{{ item.teachername ? item.teachername : "哈哈哈" }}
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="item.time_id"
              placeholder="请选择时间段"
              clearable
              style="width: 150px; margin-left: 10px"
              class="filter-item"
            >
              <el-option label="上午" :value="1" />
              <el-option label="下午" :value="2" />
              <el-option label="晚上" :value="3" />
            </el-select>
            <el-select
              v-model="item.class_id"
              placeholder="请选择某节课"
              clearable
              style="width: 150px; margin-left: 10px"
              class="filter-item"
            >
              <el-option label="第1节" :value="1" />
              <el-option label="第2节" :value="2" />
              <el-option label="第3节" :value="3" />
            </el-select>
          </el-form-item>
          <el-form-item label="课室" prop="core">
            <el-input
              v-model="item.classRoom"
              placeholder="请输入课室"
              style="width: 200px"
            ></el-input>
          </el-form-item>
          <el-form-item label="上课要求" prop="core">
            <el-input
              v-model="item.requested"
              type="textarea"
              style="width: 400px"
              :rows="4"
              placeholder="请输入课室"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="diaLogBox_btn">
          <el-button @click="shiftUp(key)">上移</el-button>
          <el-button
            @click="shiftDown(key)"
            style="margin-left: 0; margin-top: 10px"
            >下移</el-button
          >
        </div>
      </div>
    <!-- </div> -->
    <div class="btn">
      <el-button @click="$router.go(-1)">返回</el-button>
      <el-button type="primary" @click="submit" :loading="btnLoading">提交</el-button>
    </div>
  </div>
</template>
<script>
import request from "@/utils/request";
export default {
  data() {
    return {
      calssList: [
        {
          className: "",
          teachername: "hahah",
          time_id: "",
          class_id: "",
          classRoom: "",
          requested: "",
        },
        {
          className: "",
          teachername: "hahah",
          time_id: "",
          class_id: "",
          classRoom: "",
          requested: "",
        },
        {
          className: "",
          teachername: "hahah",
          time_id: "",
          class_id: "",
          classRoom: "",
          requested: "",
        },
      ],
      semester: "",
      options: [],
      professionals: [],
      value: "",
      input: "",
      btnLoading: false,
    };
  },
  created() {},
  mounted() {},
  methods: {
    submit() {
      this.btnLoading=true
      console.log(this.list);
      request({
        url: "/api/schoolend/school/schoolStore",
        method: "post",
        data: this.form,
      }).then((response) => {
        this.btnLoading = false
      });
    },
    //上移下移
    shiftUp(key) {
      if (key == 0) {
        this.$message({
          message: "已是第一条不能上移",
          type: "warning",
        });
        return;
      }
      //在上一项插入该项
      this.calssList.splice(key - 1, 0, this.calssList[key]);
      //删除后一项
      this.calssList.splice(key + 1, 1);
    },
    shiftDown(key) {
      if (key === this.calssList.length - 1) {
        this.$message({
          message: "已是最后一条不能下移",
          type: "warning",
        });
        return;
      }
      // 在下一项插入该项
      this.calssList.splice(key + 2, 0, this.calssList[key]);
      // 删除前一项
      this.calssList.splice(key, 1);
    },
  },
};
</script>
<style lang="scss" scoped>
.top {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  .top_titile {
    margin-right: 20px;
  }
}
.tips {
  width: 100%;
  text-align: center;
  margin-top: 40px;
}
.box {
  border: 1px solid #000;
  padding: 20px;
  margin-top: 20px;
}
.btn {
  display: flex;
  justify-content: center;
  margin-top: 80px;
}
.diaLogBox {
  position: relative;
  padding: 10px;
  border: 1px solid #dcdfe6;
  margin-top: 20px;

  .diaLogBox_btn {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 90px;
    bottom: 36px;
  }
}
</style>